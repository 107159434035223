<template>
    <div>
        <MXPageLayout>
            <div slot="filter">
                <Row>
                    <Form style="display:flex;justify-content:end" ref="formInline" :model="fromData" inline :label-width="10" label-position="right">
                        <FormItem>
                            <DatePicker @on-change="fromData.startTime = $event" :value="fromData.startTime" format="yyyy-MM-dd" type="date" placeholder="开始时间" style="width: 180px">
                            </DatePicker>
                        </FormItem>
                        <FormItem>
                            <DatePicker @on-change="onChangeEndTime" :value="fromData.endTime" format="yyyy-MM-dd" type="date" placeholder="结束时间" style="width: 180px">
                            </DatePicker>
                        </FormItem>
                        <FormItem>
                            <Select v-model="fromData.timeFlag" placeholder="选择日期" clearable style="width:120px">
                                <Option v-for="(item, index) in timeType" :key="index" :value="item.value">
                                    {{ item.lable }}</Option>
                            </Select>
                        </FormItem>
                        <FormItem>
                            <Select v-model="type" placeholder="统计类型" clearable style="width:120px">
                                <Option v-for="(item, index) in statisticsType" :key="index" :value="item.value">
                                    {{ item.lable }}</Option>
                            </Select>
                        </FormItem>
                        <FormItem>
                            <Select v-model="fromData.villageId" placeholder="全部小区" clearable style="width:120px">
                                <Option v-for="(item, index) in villageFormItemSelectList" :key="index" :value="item.v_village_id">
                                    {{ item.v_village_name }}</Option>
                            </Select>
                        </FormItem>
                        <FormItem>
                            <Select v-model="fromData.elevatorCode" placeholder="全部电梯" clearable style="width:120px">
                                <Option v-for="(item, index) in elevatorSelectList" :key="index" :value="item.v_elevator_code">
                                    {{ item.v_elevator_name }}</Option>
                            </Select>
                        </FormItem>
                        <Button type="primary" shape="circle" icon="ios-search" @click="searchList()">搜索
                        </Button>
                    </Form>
                </Row>
            </div>
            <div slot="title">
                {{pageTitle}}
            </div>
            <div slot="content">
                <div v-show="type == 1" class="echart-box" id="fault" style="
        width:100%;height:600px;"></div>
                <div v-show="type == 2" class="echart-box" id="maintenance" style="
        width:100%;height:600px;"></div>
                <div v-show="type == 3" class="echart-box" id="Uncivilized" style="
        width:100%;height:600px;"></div>
            </div>
        </MXPageLayout>
    </div>
</template>
<script>
import {
    getUncivilizedTrend,
    getMaintenanceTrend,
    getRepairTrend
} from '@/api/ststistics/index.js'
import { searchVillageSelectList } from '@/api/home/index.js'
import * as ElevatorApi from '@/api/elevator/index'
import Statistics from '@/pages/Statistics/statistics'
import * as echarts from 'echarts'
export default {
    data() {
        return {
            villageFormItemSelectList: [],
            elevatorSelectList: [],
            fromData: {
                startTime: this.formatDate(-90),
                endTime: this.formatDate(0),
                timeFlag: '11',
                villageId: '',
                elevatorCode: ''
            },
            type: '3',
            timeType: [{
                    value: '00',
                    lable: '日'
                },
                {
                    value: '11',
                    lable: '周'
                },
                {
                    value: '22',
                    lable: '月'
                }
            ],
            statisticsType: [{
                    value: '1',
                    lable: '急修工单'
                },
                {
                    value: '2',
                    lable: '维保工单'
                },
                {
                    value: '3',
                    lable: '不文明行为'
                }
            ],

            elevatorData: { vVillageId: '', vProjectId: '' },
            zoom: 1,

            option: {
                title: {
                    textStyle: {
                        color: '#fff'
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                legend: {
                    data: ['电动车入梯趋势', '反复阻挡门趋势'],
                    textStyle: {
                        color: '#666',
                        fontSize: 16,
                        fontWeight: 'normal'
                    }
                },
                xAxis: [{
                    type: 'category',
                    data: [],
                    axisLabel: {
                        interval: 0,
                        rotate: 30,
                        textStyle: {
                            fontSize: 12,
                            color: '#fff'
                        }
                    }
                }],
                yAxis: [{
                        type: 'value',
                        name: '',
                        min: 0,
                        interval: 50,
                        name: '电动车入梯次数',
                        axisLabel: {
                            textStyle: {
                                fontSize: 16,
                                color: '#fff'
                            }
                        },
                        nameTextStyle: {
                            fontSize: 16,
                            color: '#fff'
                        }
                    },
                    {
                        type: 'value',
                        name: '',
                        min: 0,
                        name: '反复阻挡门次数',
                        axisLabel: {
                            textStyle: {
                                fontSize: 16,
                                color: '#fff'
                            }
                        },
                        nameTextStyle: {
                            fontSize: 16,
                            color: '#fff'
                        }
                    }
                ],
                series: [],
                dataZoom: [{ // 这是一个 dataZoom 组件，可以在 x 轴上进行区域缩放
                    type: 'slider', // 这里设置 dataZoom 的类型为 slider
                    start: 0, // 数据窗口范围的起始百分比
                    end: 90, // 数据窗口范围的结束百分比
                    show: true
                }, {
                    // 没有下面这块的话，只能拖动滚动条，鼠标滚轮在区域内不能控制外部滚动条(当前滑块可控制)
                    type: 'inside',
                    realtime: true,
                    start: 0,
                    end: 100,
                }]
            },
            option2: {
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                    axisLabel: {
                        rotate: 30,
                        textStyle: {
                            fontSize: 12,
                            color: '#fff'
                        }
                    }
                },
                tooltip: {
                    trigger: 'axis'
                },

                yAxis: {
                    type: 'value',
                    name: '维保次数',
                    axisLabel: {
                        textStyle: {
                            fontSize: 16,
                            color: '#fff'
                        }
                    },
                    nameTextStyle: {
                        fontSize: 16,
                        color: '#fff'
                    }
                },
                series: [{
                    data: [820, 932, 901, 934, 1290, 1330, 1320],
                    type: 'line',
                    color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [{
                            offset: 0,
                            color: '#0ff'
                        },
                        {
                            offset: 1,
                            color: '#00aff8'
                        }
                    ]),
                    areaStyle: {}
                }],
                dataZoom: [{ // 这是一个 dataZoom 组件，可以在 x 轴上进行区域缩放
                    type: 'slider', // 这里设置 dataZoom 的类型为 slider
                    start: 0, // 数据窗口范围的起始百分比
                    end: 90, // 数据窗口范围的结束百分比
                    show: true
                }, {
                    // 没有下面这块的话，只能拖动滚动条，鼠标滚轮在区域内不能控制外部滚动条(当前滑块可控制)
                    type: 'inside',
                    realtime: true,
                    start: 0,
                    end: 100,
                }]
            },
            option3: {
                backgroundColor: '',
                title: {
                    textStyle: {
                        color: '#fff'
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                legend: {
                    data: ['急修工单趋势', '困人工单趋势'],
                    textStyle: {
                        color: '#fff',
                        fontSize: 16,
                        fontWeight: 'normal'
                    }
                },
                xAxis: [{
                    type: 'category',
                    data: ['外围分布', '瑞金总院'],
                    axisLabel: {
                        interval: 0,
                        rotate: 30,
                        textStyle: {
                            fontSize: 12,
                            color: '#fff'
                        }
                    }
                }],
                yAxis: [{
                        type: 'value',
                        name: '',
                        min: 0,
                        interval: 2,
                        name: '急修工单次数',
                        axisLabel: {
                            textStyle: {
                                fontSize: 16,
                                color: '#fff'
                            }
                        },
                        nameTextStyle: {
                            fontSize: 16,
                            color: '#fff'
                        }
                    },
                    {
                        type: 'value',
                        min: 0,
                        interval: 1,
                        name: '困人工单次数',
                        axisLabel: {
                            textStyle: {
                                fontSize: 16,
                                color: '#fff'
                            }
                        },
                        nameTextStyle: {
                            fontSize: 16,
                            color: '#fff'
                        }
                    }
                ],
                series: [{
                        name: '全部',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function(value) {
                                return value + ' ml'
                            }
                        },
                        data: [6, 124]
                    },
                    {
                        name: '困人(比率)',
                        type: 'line',
                        yAxisIndex: 1,
                        tooltip: {
                            valueFormatter: function(value) {
                                return value + ' °C'
                            }
                        },
                        data: [0, 0.01773]
                    }
                ],
                dataZoom: [{ // 这是一个 dataZoom 组件，可以在 x 轴上进行区域缩放
                    type: 'slider', // 这里设置 dataZoom 的类型为 slider
                    start: 0, // 数据窗口范围的起始百分比
                    end: 90, // 数据窗口范围的结束百分比
                    show: true
                }, {
                    // 没有下面这块的话，只能拖动滚动条，鼠标滚轮在区域内不能控制外部滚动条(当前滑块可控制)
                    type: 'inside',
                    realtime: true,
                    start: 0,
                    end: 100,
                }]
            }
        }
    },
    computed: {
        theme() {
            return this.$store.getters.theme
        },
        user() {
            return this.$store.state.user
        }
    },
    mixins: [Statistics],
    mounted() {
        searchVillageSelectList(this.buildParams({})).then(res => {
            this.villageFormItemSelectList = res.info
        })
        this.searchList()
    },

    watch: {
        'fromData.villageId': function(newObj, OldObj) {
            if (newObj) {
                this.elevatorData.vVillageId = newObj
                ElevatorApi.searchElevatorNotPage(this.elevatorData).then(res => {
                    this.elevatorSelectList = res.info
                })
            } else {
                this.fromData.elevatorCode = ''
                this.elevatorSelectList = []
            }
        },
        type: function(newObj) {
            if (newObj == 1) {
                this.getRepairTrend()
            } else if (newObj == 2) {
                this.getMaintenanceTrend()
            } else {
                this.getUncivilizedTrend()
            }
        },

        theme(newVal, oldVal) {
            if (newVal != oldVal) {
                this.searchList()
            }
        }
    },

    methods: {
        searchList() {
			if(!this.fromData.timeFlag){
				this.$message.error("请选择日期")
				return
			}
			
            this.type == 1 ?
                this.getRepairTrend() :
                this.type == 2 ?
                this.getMaintenanceTrend() :
                this.getUncivilizedTrend()
        },

        /** 不文明行为趋势 */
        getUncivilizedTrend() {
            let params = this.formatTimeParams('startTime', 'endTime', this.fromData)
            getUncivilizedTrend(params).then(res => {
                let myChart = this.$echarts.init(document.getElementById('Uncivilized'))
                this.chart = myChart
                if (res.info) {
                    this.option.xAxis[0].data = res.info.categories.map(item => {
                        return {
                            value: item,
                            textStyle: {
                                color: this.theme == 'dark' ? '#fff' : '',
                                fontSize: 16
                            }
                        }
                    })
                    let arr = []
                    let arr2 = []
                    let dataLength = 0
                    res.info.series.forEach(item => {
                        arr.push(item.name)
                        if (item.type == 'column') {
                            arr2.push({
                                name: item.name,
                                type: 'bar',
                                barWidth: item.data.length > 10 ? 'auto' : 30,
                                tooltip: {
                                    valueFormatter: function(value) {
                                        return value
                                    }
                                },
                                color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [{
                                        offset: 0,
                                        color: '#0ff'
                                    },
                                    {
                                        offset: 1,
                                        color: '#00aff8'
                                    }
                                ]),
                                data: item.data
                            })
                        } else {
                            arr2.push({
                                name: item.name,
                                type: 'line',
                                tooltip: {
                                    valueFormatter: function(value) {
                                        return value
                                    }
                                },
                                color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [{
                                        offset: 0,
                                        color: '#0ff'
                                    },
                                    {
                                        offset: 1,
                                        color: '#00aff8'
                                    }
                                ]),
                                yAxisIndex: 1,
                                data: item.data,
                            })
                        }
                        dataLength = item.data ? item.data.length : 0
                    })
                    const dataZoomEnd = dataLength > 13 ? (13 / dataLength) * 100 : 100
                    this.option.dataZoom[0].show = dataZoomEnd != 100
                    this.option.dataZoom[0].end = dataZoomEnd
                    this.option.dataZoom[1].end = dataZoomEnd

                    this.option.yAxis.forEach(item => {
                        item.axisLabel.color = this.theme == 'dark' ? '#fff' : ''
                    })
                    this.option.series = arr2
                    myChart.setOption(this.option)
                    myChart.resize()
                }
            })
        },
        /** 维保工单趋势 */
        getMaintenanceTrend() {
            let params = this.formatTimeParams('startTime', 'endTime', this.fromData)
            getMaintenanceTrend(params).then(res => {
                if (res.info) {
                    let myChart = this.$echarts.init(
                        document.getElementById('maintenance')
                    )
                    this.option2.xAxis.data = res.info.categories.map(item => {
                        return {
                            value: item,
                            textStyle: {
                                color: this.theme == 'dark' ? '#fff' : '',
                                fontSize: 16
                            }
                        }
                    })

                    let dataLength = res.info.categories.length

                    const dataZoomEnd = dataLength > 13 ? (13 / dataLength) * 100 : 100
                    this.option2.dataZoom[0].show = dataZoomEnd != 100
                    this.option2.dataZoom[0].end = dataZoomEnd
                    this.option2.dataZoom[1].end = dataZoomEnd

                    this.option2.series[0].data = res.info.series[0].data
                    this.option2.yAxis.axisLabel.color =
                        this.theme == 'dark' ? '#fff' : ''
                    myChart.setOption(this.option2)
                    myChart.resize()
                }
            })
        },
        /** 急修工单趋势 */
        getRepairTrend() {
            let params = this.formatTimeParams('startTime', 'endTime', this.fromData)
            getRepairTrend(params).then(res => {
                console.log(res, 'res')
                let myChart = this.$echarts.init(document.getElementById('fault'), 'dark')
                if (res.info) {
                    this.option3.xAxis[0].data = res.info.categories.map(item => {
                        return {
                            value: item,
                            textStyle: {
                                color: this.theme == 'dark' ? '#fff' : '',
                                fontSize: 16
                            }
                        }
                    })

                    let arr = []
                    let arr2 = []
                    let dataLength = 0

                    res.info.series.forEach(item => {
                        arr.push(item.name)
                        if (item.type == 'column') {
                            arr2.push({
                                name: item.name,
                                type: 'bar',
                                barWidth: item.data.length > 10 ? 'auto' : 30,

                                tooltip: {
                                    valueFormatter: function(value) {
                                        return value
                                    }
                                },
                                color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [{
                                        offset: 0,
                                        color: '#0ff'
                                    },
                                    {
                                        offset: 1,
                                        color: '#00aff8'
                                    }
                                ]),
                                data: item.data
                            })
                        } else {
                            arr2.push({
                                name: item.name,
                                type: 'line',
                                tooltip: {
                                    valueFormatter: function(value) {
                                        return value
                                    }
                                },
                                color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [{
                                        offset: 0,
                                        color: '#0ff'
                                    },
                                    {
                                        offset: 1,
                                        color: '#00aff8'
                                    }
                                ]),
                                yAxisIndex: 1,
                                data: item.data
                            })
                        }
                        dataLength = item.data ? item.data.length : 0
                    })

                    const dataZoomEnd = dataLength > 13 ? (13 / dataLength) * 100 : 100
                    this.option3.dataZoom[0].show = dataZoomEnd != 100
                    this.option3.dataZoom[0].end = dataZoomEnd
                    this.option3.dataZoom[1].end = dataZoomEnd

                    this.option3.yAxis.forEach(item => {
                        item.axisLabel.color = this.theme == 'dark' ? '#fff' : ''
                    })
                    // this.option.legend.data = arr
                    this.option3.series = arr2
                    myChart.setOption(this.option3)
                    myChart.resize()
                }
            })
        },
        updatePageTitle() {
            if (this.fromData.villageId && this.fromData.villageId.length) {
                let find = this.villageFormItemSelectList.find((village) => {
                    return village.v_village_id == this.fromData.villageId
                })
                if (this.fromData.elevatorCode && this.fromData.elevatorCode.length) {
                    let elevator = this.elevatorSelectList.find((elevator) => {
                        return elevator.v_elevator_code == this.fromData.elevatorCode
                    })
                    if (elevator) {
                        this.pageTitle = `${elevator.v_elevator_name}${this.getModuleName()}`
                    }
                } else {
                    if (find) {
                        this.pageTitle = `${find.v_village_name}${this.getModuleName()}`
                    }
                }

            } else {
                this.pageTitle = `全部小区${this.getModuleName()}`
            }
        },

        getModuleName() {
            return '历史走势图'
        },

        onChangeEndTime(e) {
            this.fromData.endTime = e
        },
        buildParams(params) {
            params.vProjectId = this.user.user.v_project_id
            return params
        }
    }
}
</script>
<style scoped>
.home {
    padding: 10px 30px 0 10px;
}

::v-deep .ivu-select-selected-value {
    color: #fff !important;
}

#fault {
    width: 100%;
}
</style>